<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.insurance_company") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.name.title" prop="name">
            <crm-input
              v-model="form.name"
              :inputValue="form.name"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      data: [],
      services: [],
      fileList: [],
      imageList: [],
      fullImageList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      drawerPatientService: false,
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
    };
  },

  created() {},

  computed: {
    ...mapGetters({
      model: "insuranceCompany/model",
      rules: "insuranceCompany/rules",
      columns: "insuranceCompany/columns",
    }),
  },

  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      destroy: "clinicImages/destroy",
      save: "insuranceCompany/update",
    }),
    async afterOpened() {},
    afterClosed() {
      this.resetForm("form");
      // this.$refs.items.setCheckedNodes([]);
      this.data = [];
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    submitTransaction(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.parent().listChanged();
              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.clinic_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFull(res, file) {
      this.form.clinic_full_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFile(res, file) {
      this.form.patient_file_id = res.data.patientFiles.id;
    },

    handleRemove(file, fileList) {
      this.destroy(file.response.data.clinicImages.id).then((res) => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    handlePreview(file) {
      console.log(file);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.data = [];
      this.imageList = [];
      this.fullImageList = [];
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style>
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>